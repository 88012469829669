import axios from 'axios';

const http = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}`,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json'
    }
});
const httpLogin = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}`,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

const authService = {
    register: async (userData) => {
        return authService.post('user/register', userData);
    },

    // login: async (credentials) => {
    //   return authService.postLogin('user/login', credentials);
    // },

    login: async (credentials) => {
        // Ensuring grant_type is included with the credentials for proper authentication
        const data = {
            ...credentials,
            grant_type: 'password'
        };
        // Encoding the parameters to suit content type application/x-www-form-urlencoded
        const formEncodedData = new URLSearchParams(data).toString();
        return authService.postLogin('user/login', formEncodedData);
    },

    // Private method, not exported
    postLogin: async (endpoint, data) => {
        try {
            const response = await httpLogin.post(endpoint, data);
            return response.data;
        } catch (error) {
            console.error(`Error during POST request to ${endpoint}:`, error);
            throw error;
        }
    },
    post: async (endpoint, data) => {
        try {
            const response = await http.post(endpoint, data);
            return response.data;
        } catch (error) {
            console.error(`Error during POST request to ${endpoint}:`, error);
            throw error;
        }
    },


};

export default authService;
